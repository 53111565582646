.EditImagePreviewCard {
    background-color: $n200;
    position: relative;
    padding: 0.5rem;
    flex-shrink: 0;

    .EditImagePreviewCard__img {
        height: 16rem;
        width: 16rem;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }

    .EditImagePreviewCard__del-btn {
        position: absolute;
        top: 0;
        right: 0;
    }
}
