.SlateEditorCustomImage__container {
    .SlateEditorCustomImage__inner-container {
        position: relative;
        width: 100%;
        height: 32rem;

        .SlateEditorCustomImage__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .SlateEditorCustomImage__edit {
            position: absolute;
            width: fit-content;
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
        }
    }

    .SlateEditorCustomImage__heading {
        display: inline-block;
        width: 100%;
        margin-top: 1rem;
        text-align: center;
        font-style: italic;
    }
}
