.SingleArticlePage {
    .SingleArticlePage__header-img {
        width: 100%;
        border-radius: 0.5rem;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    .markdown-content {
        img {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 0.5rem;
            margin-top: 2rem;
            margin-bottom: 3rem;
        }
    }
}
