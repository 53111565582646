.SlateEditable--modify {
    border-radius: 0 0 0.375rem 0.375rem;
    border: 2px solid $n300;
    border-top: 0;
    padding: 0.5rem 0.75rem;
    height: 42rem;
    overflow-y: auto;

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        border-color: #86b7fe;
    }
}

.SlateEditable--readOnly {
    
}