.Footer-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

@include media-breakpoint-down(lg) {
    .Footer-items {
        flex-direction: column-reverse;
        justify-content: space-evenly;
    }
}
