.Startseite {
    .Startseite__header {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: calc(100vh - 3.5rem - 2rem);
    }

    .Startseite__announcements {
        background-color: $b700;
        height: 29.25rem;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .Startseite__announcements-bg {
        position: absolute;
        width: 100%;
        height: 29.25rem;
        left: 0;
        transform: translateY(-100%);
        z-index: -1;
        background-color: $b700;
    }

    .Startseite__title {
        margin-bottom: 4rem;
    }

    .Startseite__children-image {
        width: 90%;
    }

    .Startseite__children-image--boy {
        float: left;
    }

    .Startseite__children-image--girl {
        float: right;
    }

    .Startseite__header__mobileImg {
        width: 75%;
    }
}

@include media-breakpoint-down(xl) {
    .Startseite {
        .Startseite__announcements {
            height: 42rem;
        }
        .Startseite__announcements-bg {
            height: 42rem;
        }
    }
}

@include media-breakpoint-down(lg) {
    .Startseite {
        .Startseite__title {
            margin-top: 2rem;
            margin-bottom: 2rem;
            text-align: center;
        }
    }
}

@include media-breakpoint-down(md) {
    .Startseite {
        .Startseite__header__mobileImg {
            width: 100%;
        }
    }
}
