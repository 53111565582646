.Error403 {
    .Error403__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50vw;

        .Error__403__icon {
            display: block;
            margin: 1rem auto;
            width: 6rem;
            height: auto;
        }

        h1 {
            color: $r700;
            text-align: center;
        }
    }
}

@include media-breakpoint-down(md) {
    .Error403 {
        .Error403__content {
            width: 90vw;
        }
    }
}
