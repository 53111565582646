.FloatingActionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 3vw;
    bottom: 3vw;
    width: 3.927rem;
    height: 3.927rem;
    background-color: $o700;
    border: none;
    border-radius: 50%;
    z-index: 100;

    &:hover {
        background-color: lighten($o700, $colorHoverLightenPercentage);
    }

    &:active {
        background-color: lighten($o700, $colorActiveLightenPercentage);
    }

    .FloatingActionButton__icon {
        width: 1.5rem;
        height: 1.5rem;
        fill: $n100;
    }
}
