.ArticleCardSkeleton {
    display: flex;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: background 0.15s ease-in-out;

    .ArticleCardSkeleton__img-container {
        width: 26.25rem;
        height: 16.25rem;
        border-radius: 0.5rem;
        overflow: hidden;
        flex-shrink: 0;
    }

    .ArticleCardSkeleton__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 4rem;

        .ArticleCardSkeleton__attentionText {
            height: 1.25rem;
        }
    }
}
