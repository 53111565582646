@include media-breakpoint-down(lg) {
    .Beitritt {
        .Beitritt_leftSide {
            margin-bottom: 4rem;

            h2 {
                margin-top: 0;
            }
        }
    }
}
