.StartseiteNextEventCard {
    position: relative;
    width: 12rem;
    border-radius: 1rem;
    border: 2px solid $b400;
    overflow: hidden;
    color: $n900;
    margin-right: 1rem;
    flex-shrink: 0;
    cursor: pointer;
}

.StartseiteNextEventCard__date {
    height: 8rem;
    background-color: $b100;
    font: {
        size: nth($headingFontSizes, 3);
        weight: $headingWeight;
        family: $fontHeading;
    }
    line-height: $headingLineHeight;
    border-bottom: 1px solid $b400;
}

.StartseiteNextEventsCard__title {
    background-color: $n100;
    height: 6rem;
    padding: 0.5rem;
}
.StartseiteNextEventsCard__title > span {
    text-align: center;
    color: $n700;
}

.StartseiteNextEventCard--red {
    border: 2px solid $r700;
}
.StartseiteNextEventCard--red > .StartseiteNextEventCard__date {
    background-color: $r200;
    border-bottom: 1px solid $r700;
}

.StartseiteNextEventCard--draft {
    border: 2px solid $o700;
}
.StartseiteNextEventCard--draft > .StartseiteNextEventCard__date {
    background-color: $o100;
    border-bottom: 1px solid $o700;
}
