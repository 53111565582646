.VerticalLine {
    width: 4px;
    border-radius: 2px;
    height: 100%;
    background-color: rgba($color: #fff, $alpha: 0.25);
}

.VerticalLine--dark {
    background-color: $n300;
}
