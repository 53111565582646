.MobileNavigationItem {
    cursor: pointer;
    user-select: none;
    color: $n700;
    transition: color 0.1s;
    text-decoration: none;
    width: 100%;
    padding: 1rem 0;
    margin: 0.25rem 0;
    border-radius: 1rem;

    &:hover {
        color: $n500;
        background-color: $n150;
    }

    &:active {
        color: $n400;
    }
}
.MobileNavigationItem--active {
    background-color: $b100;
    color: $n900;

    &:hover {
        color: $n800;
    }

    &:active {
        color: $n700;
    }
}
