.StartseiteHeaderCard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.StartseiteHeaderCard__icon-container {
    height: 4rem;
    width: 4rem;
    margin-bottom: 0.5rem;
    border-radius: 50%;
}

.StartseiteHeaderCard__icon {
    width: auto;
    height: 1.5rem;
    fill: $n100;
}
