.Kontakt {
    .Kontakt__image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-left: 16%;
    }
}

@include media-breakpoint-down(sm) {
    .Kontakt .IconButton {
        margin-bottom: 1rem;
    }
}
