.DropzoneComponent {
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: $n150;
    border-radius: 1rem;
    border: 2px dashed $n300;

    .DropzoneComponent__preview {
        width: 100%;
        height: 100%;
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .DropzoneComponent__preview-image {
            width: 8rem;
            height: 8rem;
            // background-color: $n200;
            border-radius: 0.5rem;
            border: 2px solid $n300;
            flex-shrink: 0;
            margin: 0.5rem;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }

            .DropzoneComponent__preview__cross {
                position: absolute;
                top: -0.92rem;
                right: -0.92rem;
            }
        }
    }
}

.DropzoneComponent--active {
    border: 2px dashed $n500;
}

.DropzoneComponent--accept {
    border: 2px dashed $g700;
}

.DropzoneComponent--reject {
    border: 2px dashed $r700;
}
