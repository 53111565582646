.MobileNavigationButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.6rem;
    height: 3.6rem;
    border: none;
    border-radius: 50%;
    background-color: transparent;

    &:hover {
        background-color: darken($n100, $colorHoverDarkenPercentage);
    }

    &:active {
        background-color: darken($n100, $colorActiveDarkenPercentage);
    }

    .MobileNavigationButton__icon {
        width: 1.6rem;
        height: 1.6rem;
        fill: $n900;
    }
}
