.ArticleCard {
    display: flex;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    transition: background 0.15s ease-in-out;
    position: relative;
    height: 16.25rem;

    &:hover {
        background-color: $n200;
    }

    .ArticleCard__img-container {
        width: 26.25rem;
        height: 16.25rem;
        border-radius: 0.5rem;
        overflow: hidden;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .ArticleCard__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 4rem;

        .ArticleCard__heading {
            margin-top: 1.5rem;
        }

        .ArticleCard__info-text {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .ArticleCard__attentionText {
            height: 1.25rem;
            font-variant: small-caps;
        }
    }

    &.ArticleCard--draft {
        background-color: $o100;

        &:hover {
            background-color: $o400;
        }
    }
}

@include media-breakpoint-down(lg) {
    .ArticleCard {
        height: 10rem;

        .ArticleCard__img-container {
            width: 16.18rem;
            height: 10rem;
        }

        .ArticleCard__info {
            margin-left: 2rem;

            .ArticleCard__heading {
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .ArticleCard {
        height: 10rem;

        .ArticleCard__img-container {
            width: 10rem;
            height: 10rem;
        }

        .ArticleCard__info {
            margin-left: 1rem;

            .ArticleCard__heading {
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
            }
        }
    }
}
