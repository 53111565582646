.AdminLogin {
    .AdminLogin__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20rem;

        h1 {
            margin-top: 0;
        }
    }

    .AdminLogin__error {
        width: 100%;
        padding: 1.25rem;
        background-color: $r200;
        border: 2px solid $r400;
        border-radius: 0.5rem;
    }
}
