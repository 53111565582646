.DonateButton {
  position: relative;
  overflow: hidden;

  height: 3.25rem;
  width: 16rem;
  border-radius: 1.625rem;
  border: 3px solid $r700;

  background-color: $r100;
  color: $n800;

  .DonateButton__leftSide {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    width: 25%;
    height: 100%;
    background-color: $r700;
    transition: width 1.5s ease-in-out;

    .DonateButton__leftSide__icon {
      height: 1.5rem;
      width: auto;
      fill: $n100;
      transform: rotate(0deg);
      transition: transform 1.5s ease-in-out;
    }
  }

  .DonateButton__rightSide {
    width: 100%;
    padding-left: 4rem;

    & > span {
      text-align: center;
    }
  }

  &:hover {
    .DonateButton__leftSide {
      width: 100%;

      .DonateButton__leftSide__icon {
        transform: rotate(359deg);
      }
    }
  }
}
