.ImageSlider {
    width: 100%;
    aspect-ratio: 1.618 / 1;
    overflow: hidden;

    .carousel-inner {
        width: 100%;
        height: 100%;
    }

    img {
        display: block;
        border-radius: 1rem;
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
    }
}
