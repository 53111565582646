.NavigationItem {
    cursor: pointer;
    user-select: none;
    color: $n700;
    transition: color 0.1s;
    text-decoration: none;

    &:hover {
        color: $n500;
    }

    &:active {
        color: $n400;
    }
}

.NavigationItem--active {
    border-bottom: 2px solid $n900;
    color: $n900;

    &:hover {
        border-bottom: 2px solid $n800;
        color: $n800;
    }

    &:active {
        border-bottom: 2px solid $n700;
        color: $n700;
    }
}
