.VorstandCard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $n150;
    border-radius: 1rem;

    height: 13.25rem;
    width: calc(2 * 13.25rem);
    margin: 0 auto;

    .VorstandCard__image {
        position: absolute;
        top: 0;
        transform: translateY(-50%);

        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@include media-breakpoint-down(lg) {
    .VorstandCard {
        border-radius: 1rem;

        height: 10.5rem;
        width: calc(2 * 10.5rem);

        .VorstandCard__image {
            width: 5rem;
            height: 5rem;
        }
    }
}
