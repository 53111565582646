.SmallIconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.618rem;
    height: 2.618rem;
    border: none;
    border-radius: 50%;
    background-color: transparent;

    &:hover {
        background-color: darken($n100, $colorHoverDarkenPercentage);
    }

    &:active {
        background-color: darken($n100, $colorActiveDarkenPercentage);
    }

    .SmallIconButton__icon {
        width: 1rem;
        height: 1rem;
        fill: $n900;
    }
}

.SmallIconButton--smaller {
    width: 1.84rem;
    height: 1.84rem;

    .SmallIconButton__icon {
        width: 0.7rem;
        height: 0.7rem;
    }
}

.SmallIconButton--primary {
    background-color: $b700;

    &:hover {
        background-color: lighten($b700, $colorHoverLightenPercentage);
    }

    &:active {
        background-color: lighten($b700, $colorActiveLightenPercentage);
    }

    .SmallIconButton__icon {
        fill: $n100;
    }
}

.SmallIconButton--warning {
    background-color: $o700;

    &:hover {
        background-color: lighten($o700, $colorHoverLightenPercentage);
    }

    &:active {
        background-color: lighten($o700, $colorActiveLightenPercentage);
    }

    .SmallIconButton__icon {
        fill: $n100;
    }
}

.SmallIconButton--danger {
    background-color: $r700;

    &:hover {
        background-color: lighten($r700, $colorHoverLightenPercentage);
    }

    &:active {
        background-color: lighten($r700, $colorActiveLightenPercentage);
    }

    .SmallIconButton__icon {
        fill: $n100;
    }
}
