.DonationCompletedModal__bg {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0003;

  .DonationCompletedModal {
    position: relative;
    width: 1024px;
    max-height: 90%;
    overflow-y: auto;
    background-color: $n100;
    border-radius: 1rem;
    padding: 0.5rem 1.5rem;

    h2 {
      text-align: center;
      color: $n900;
    }

    .DonationCompletedModal__icon {
      display: block;
      margin: 3rem auto;
      fill: $r700;
      height: 5rem;
      width: auto;
    }
  }

  .DonationCompletedModal__closeBtn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@include media-breakpoint-down(xl) {
  .DonationCompletedModal__bg {
    .DonationCompletedModal {
      width: 90%;
    }
  }
}
