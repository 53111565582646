.InlineButton {
    color: $n900;
    text-decoration: none;

    &:visited {
        color: $n900;
    }

    &:hover {
        color: $n700;
        text-decoration: underline;
    }

    &:active {
        color: $n500;
    }
}

.InlineButton--staticUnderline {
    text-decoration: underline;
}

.InlineButton--light {
    color: $n100;

    &:visited {
        color: $n100;
    }

    &:hover {
        color: $n300;
    }

    &:active {
        color: $n500;
    }
}
