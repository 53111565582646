.SlateEditorUploadImageModalContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    .SlateEditorUploadImageModal {
        padding: 1.5rem;
        border: 1px solid $n300;
        background-color: $n150;
        border-radius: 1rem;
        width: 1000px;

        .SlateEditorUploadImageModal__close {
            position: absolute;
            width: fit-content;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
        }
    }
}
