// Calistoga
@font-face {
    font-family: "Calistoga";
    src: local("Calistoga"),
        url("../assets/fonts/Calistoga/Calistoga-Regular.ttf");
    font-weight: 400;
}

// Source Sans Pro
@font-face {
    font-family: "Source Sans Pro";
    src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
    font-weight: 400;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf");
    font-weight: 600;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
    font-weight: 700;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("../assets/fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
}

// fonts
$headingLineHeight: 125%;
$headingWeight: 400;
.title {
    font-size: 3.5rem;
    font-weight: $headingWeight;
    line-height: $headingLineHeight;
    font-family: $fontHeading;
    margin: 0 auto;
}

$headingFontSizes: 2.5rem, 2rem, 1.8125rem, 1.5rem, 1.25rem;
$headingFontSizesMobile: 2rem, 1.8125rem, 1.5rem, 1.25rem, 1rem;
$headingMarginBottom: 3rem, 2rem, 1rem, 0.75rem, 0.5rem;
@for $i from 1 through length($headingFontSizes) {
    h#{$i} {
        font: {
            size: nth($headingFontSizes, $i);
            weight: $headingWeight;
            family: $fontHeading;
        }
        line-height: $headingLineHeight;
        margin: nth($headingMarginBottom, $i) 0;
    }
}

@include media-breakpoint-down(xl) {
    .title {
        font-size: 2.5rem;
    }

    @for $i from 1 through length($headingFontSizes) {
        h#{$i} {
            font: {
                size: nth($headingFontSizesMobile, $i);
            }
            margin: nth($headingMarginBottom, $i) 0;
        }
    }
}

$bodyLineHeight: 150%;
$labelLineHeight: 125%;
$bodyFontSizes: 1.25rem, 1.125rem, 1rem, 0.875rem, 0.75rem;
$bodyFontSizesMobile: 1.125rem, 1rem, 0.875rem, 0.75rem, 0.625rem;
@for $i from 0 through 4 {
    // body
    .b#{$i}-r {
        font: {
            size: nth($bodyFontSizes, $i + 1);
            weight: 400;
            family: $fontBody;
        }
        line-height: $bodyLineHeight;
    }
    .b#{$i}-sb {
        @extend .b#{$i}-r;
        font-weight: 600;
    }
    .b#{$i}-b {
        @extend .b#{$i}-r;
        font-weight: 700;
    }

    // labels
    .l#{$i}-r {
        @extend .b#{$i}-r;
        line-height: $labelLineHeight;
    }
    .l#{$i}-sb {
        @extend .l#{$i}-r;
        font-weight: 600;
    }
    .l#{$i}-b {
        @extend .l#{$i}-r;
        font-weight: 700;
    }
}

@include media-breakpoint-down(lg) {
    @for $i from 0 through 4 {
        // body
        .b#{$i}-r {
            font: {
                size: nth($bodyFontSizesMobile, $i + 1);
            }
        }
    }
}

.logo-font {
    font: {
        weight: 400;
        size: 1.125rem;
        family: $fontBody;
        variant: small-caps;
    }
    line-height: 110%;
    letter-spacing: 0.08rem;
}
