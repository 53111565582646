$theme-colors: (
    "primary": $b700,
    "success": $g700,
    "danger": $r700,
    "warning": $o700,
    "light": $n100,
    "dark": $n900,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1600px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    xxxl: 1520px,
);

@import "~bootstrap/scss/bootstrap";

.placeholder {
    background-color: $n700;
}
