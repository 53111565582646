@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import "./styles/variables";
@import "./styles/colors";
@import "./styles/custom-bootstrap.scss";
@import "./styles/fonts.scss";

@import "./components/footer/Footer.scss";
@import "./components/navigation/Navigation.scss";
@import "./components/admin/editPage/EditPage.scss";
@import "./components/common/forms/input/Input.scss";
@import "./components/common/imageSlider/ImageSlider.scss";
@import "./components/common/articleCard/ArticleCard.scss";
@import "./components/common/forms/textarea/Textarea.scss";
@import "./components/common/verticalLine/VerticalLine.scss";
@import "./components/common/buttons/iconButton/IconButton.scss";
@import "./components/common/horizontalLine/HorizontalLine.scss";
@import "./components/helpers/alertContainer/AlertContainer.scss";
@import "./components/common/buttons/inlineButton/InlineButton.scss";
@import "./pages/Vorstand/components/vorstandCard/VorstandCard.scss";
@import "./components/common/buttons/donateButton/DonateButton.scss";
@import "./components/common/confirmationModal/ConfirmationModal.scss";
@import "./components/common/singleArticlePage/SingleArticlePage.scss";
@import "./pages/Satzungen/components/satzungenCard/SatzungenCard.scss";
@import "./components/common/buttons/smallIconButton/SmallIconButton.scss";
@import "./components/common/articleCardSkeleton/ArticleCardSkeleton.scss";
@import "./components/admin/contentActionButtons/ContentActionButtons.scss";
@import "./components/common/forms/dropzoneComponent/DropzoneComponent.scss";
@import "./components/navigation/components/navigationItem/NavigationItem.scss";
@import "./components/navigation/components/mobileNavigation/MobileNavigation.scss";
@import "./components/common/buttons/floatingActionButton/FloatingActionButton.scss";
@import "./components/common/slateEditor/components/slateEditable/SlateEditable.scss";
@import "./components/admin/editPage/components/editImagePreview/EditImagePreview.scss";
@import "./components/admin/editPage/components/editImageDropzone/EditImageDropzone.scss";
@import "./components/common/slateEditor/components/slateEditorToolbar/SlateEditorToolbar.scss";
@import "./components/navigation/components/mobileNavigationButton/MobileNavigationButton.scss";
@import "./components/admin/editPage/components/editDocumentDropzone/EditDocumentDropzone.scss";
@import "./components/common/slateEditor/components/slateEditorUploadImageModal/SlateEditorUploadImageModal.scss";
@import "./components/navigation/components/mobileNavigation/components/mobileNavigationItem/MobileNavigationItem.scss";
@import "./components/admin/editPage/components/editImagePreview/components/editImagePreviewCard/EditImagePreviewCard.scss";
@import "./components/common/slateEditor/components/slateEditorToolbar/components/slateEditorToolbarBtn/SlateEditorToolbarBtn.scss";
@import "./components/common/slateEditor/components/slateEditorToolbar/components/slateEditorToolbarImageBtn/SlateEditorToolbarImageBtn.scss";
@import "./components/common/slateEditor/components/slateEditable/components/slateEditorCustomElement/components/slateEditorCustomImage/SlateEditorCustomImage.scss";

@import "./pages/Kontakt/Kontakt.scss";
@import "./pages/Beitritt/Beitritt.scss";
@import "./pages/Vorstand/Vorstand.scss";
@import "./pages/Satzungen/Satzungen.scss";
@import "./pages/Startseite/Startseite.scss";
@import "./pages/AdminLogin/AdminLogin.scss";
@import "./pages/errorPages/Error403/Error403.scss";
@import "./pages/errorPages/Error404/Error404.scss";
@import "./pages/AdminDashboard/AdminDashboard.scss";
@import "./pages/ProjekteIndividual/ProjekteIndividual.scss";
@import "./pages/AdminDashboard/components/adminNavBar/AdminNavBar.scss";
@import "./pages/AdminDashboard/pages/AdminDashboardForms/AdminDashboardForms.scss";
@import "./pages/Spenden/components/donationCompletedModal/DonationCompletedModal.scss";
@import "./pages/Startseite/components/StartseiteNextEventsModal/StartseiteNextEventsModal.scss";
@import "./pages/Startseite/components/StartseiteNextEventsSlider/StartseiteNextEventsSlider.scss";
@import "./pages/AdminDashboard/pages/AdminDashboardUserManagement/AdminDashboardUserManagement.scss";
@import "./pages/AdminDashboard/pages/AdminDashboardKontaktanfragen/AdminDashboardKontaktanfragen.scss";
@import "./pages/Startseite/components/StartseiteCardSection/components/StartseiteHeaderCard/StartseiteHeaderCard.scss";
@import "./pages/Startseite/components/StartseiteNextEventsSlider/components/StartseiteNextEventCard/StartseiteNextEventCard.scss";

* {
  box-sizing: border-box;
}

body {
  @extend .b1-r;

  margin: 0;
  background-color: $n100;
  color: $n900;
}

.wrapper {
  min-height: 100vh;

  .container {
    overflow: auto;
    padding-bottom: 5rem;
  }
}

.footer {
  position: relative;
  margin-top: -5rem;
  height: 5rem;
  clear: both;
}

@include media-breakpoint-down(lg) {
  .wrapper {
    .container {
      padding-bottom: 10rem;
    }
  }

  .footer {
    margin-top: -10rem;
    height: 10rem;
  }
}

.bg-n900 {
  background-color: $n900;
}
.bg-n800 {
  background-color: $n800;
}
.bg-n700 {
  background-color: $n700;
}
.bg-n600 {
  background-color: $n600;
}
.bg-n500 {
  background-color: $n500;
}
.bg-n400 {
  background-color: $n400;
}
.bg-n300 {
  background-color: $n300;
}
.bg-n200 {
  background-color: $n200;
}
.bg-n150 {
  background-color: $n150;
}
.bg-n100 {
  background-color: $n100;
}

.bg-b700 {
  background-color: $b700;
}
.bg-b400 {
  background-color: $b400;
}
.bg-b100 {
  background-color: $b100;
}

.bg-r700 {
  background-color: $r700;
}
.bg-r400 {
  background-color: $r400;
}
.bg-r200 {
  background-color: $r200;
}
.bg-r100 {
  background-color: $r100;
}

.bg-g700 {
  background-color: $g700;
}
.bg-g400 {
  background-color: $g400;
}
.bg-g100 {
  background-color: $g100;
}

.bg-o700 {
  background-color: $o700;
}
.bg-o400 {
  background-color: $o400;
}
.bg-o100 {
  background-color: $o100;
}

.bg-y700 {
  background-color: $y700;
}
.bg-y400 {
  background-color: $y400;
}
.bg-y100 {
  background-color: $y100;
}

// text colors

.tc-n900 {
  color: $n900;
}
.tc-n800 {
  color: $n800;
}
.tc-n700 {
  color: $n700;
}
.tc-n600 {
  color: $n600;
}
.tc-n500 {
  color: $n500;
}
.tc-n400 {
  color: $n400;
}
.tc-n300 {
  color: $n300;
}
.tc-n200 {
  color: $n200;
}
.tc-n150 {
  color: $n150;
}
.tc-n100 {
  color: $n100;
}

.tc-b700 {
  color: $b700;
}
.tc-b400 {
  color: $b400;
}
.tc-b100 {
  color: $b100;
}

.tc-r700 {
  color: $r700;
}
.tc-r400 {
  color: $r400;
}
.tc-r200 {
  color: $r200;
}
.tc-r100 {
  color: $r100;
}

.tc-g700 {
  color: $g700;
}
.tc-g400 {
  color: $g400;
}
.tc-g100 {
  color: $g100;
}

.tc-o700 {
  color: $o700;
}
.tc-o400 {
  color: $o400;
}
.tc-o100 {
  color: $o100;
}

.tc-y700 {
  color: $y700;
}
.tc-y400 {
  color: $y400;
}
.tc-y100 {
  color: $y100;
}

.ic-n900 {
  fill: $n900;
}

.ic-n100 {
  fill: $n100;
}

.ic-b700 {
  fill: $b700;
}

.mb-2rem {
  margin-bottom: 2rem;
}

.mb-6rem {
  margin-bottom: 6rem;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.z-index--1 {
  z-index: -1;
}

.width-fit-content {
  width: fit-content;
}

@include media-breakpoint-up(md) {
  .mb-md-0 {
    margin-bottom: 0;
  }
}

.custom-hr {
  width: 100%;
  height: 4px;
  background-color: $n300;
  border-radius: 2px;
}
