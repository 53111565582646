// primary
$b700: #143465;
$b400: #2f72d6;
$b100: #bad0f1;

// neutral
$n900: #1b212a;
$n800: #353b44;
$n700: #5a5f67;
$n600: #7c8086;
$n500: #9fa3a9;
$n400: #b6bbc1;
$n300: #cdd1d8;
$n200: #e5e9ee;
$n150: #f2f4f6;
$n100: #fbfcfd;

// red
$r700: #b8394c;
$r400: #d67a88;
$r200: #f1d3d7;
$r100: #fff1f3;

// green
$g700: #60a054;
$g400: #97c48f;
$g100: #dcebda;

// orange
$o700: #e7951c;
$o400: #f0c043;
$o100: #f6e5b9;

// yellow
$y700: #e7d103;
$y400: #f9e743;
$y100: #fdf7c0;
