.IconButton {
    display: flex;
    justify-content: center;
    align-items: center;

    color: $n100;
    background-color: $b700;
    border: none;
    height: 2.75rem;
    width: 100%;
    border-radius: 0.5rem;

    &:hover {
        background-color: lighten($b700, $colorHoverLightenPercentage);
    }

    &:active {
        background-color: lighten($b700, $colorActiveLightenPercentage);
    }

    .IconButton__icon {
        height: 1.125rem;
        width: auto;
        margin-left: 1rem;
        fill: $n100;
    }
}

.IconButton--secondary {
    color: $n900;
    background-color: $n100;
    border: 2px solid $n300;

    &:hover {
        background-color: darken($n100, $colorHoverDarkenPercentage);
    }

    &:active {
        background-color: darken($n100, $colorActiveDarkenPercentage);
    }

    .IconButton__icon {
        fill: $n900;
    }
}

.IconButton--danger {
    color: $n100;
    background-color: $r700;

    &:hover {
        background-color: lighten($r700, $colorHoverLightenPercentage);
    }

    &:active {
        background-color: lighten($r700, $colorActiveLightenPercentage);
    }

    .IconButton__icon {
        fill: $n100;
    }
}
