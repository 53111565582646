.AdminDashboardUserManagement {
    .accordion {
        .accordion-header {
            font: {
                size: 1.125rem;
                weight: 600;
                family: $fontBody;
            }
            line-height: $bodyLineHeight;
            margin: 0;
        }
    }
}
