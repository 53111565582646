.MobileNavigation {
    background-color: $n100;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 200;
    border-radius: 0.5rem;
    overflow-y: scroll;

    .MobileNavigation__closeButton {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}
