.SlateEditorToolbarBtn {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    background-color: $n300;
    margin-right: 1rem;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 0.375rem;
    border: 0;

    .SlateEditorToolbarBtn__icon {
        height: 1.125rem;
        width: auto;
        fill: $n900;
    }

    &.SlateEditorToolbarBtn--active {
        background-color: $n900;

        .SlateEditorToolbarBtn__icon {
            fill: $n100;
        }
    }
}
