.ConfirmationModal__bg {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0003;

    .ConfirmationModal {
        position: relative;
        width: 540px;
        background-color: $n100;
        border-radius: 1rem;
        padding: 0.5rem 1.5rem;

        h2 {
            text-align: center;
            color: $r700;
        }
    }

    .ConfirmationModal__closeBtn {
        position: absolute;
        top: 0;
        right: 0;
    }
}

@include media-breakpoint-down(sm) {
    .ConfirmationModal__bg {
        .ConfirmationModal {
            width: 90%;
        }
    }
}
