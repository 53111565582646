.SatzungenCard {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $n150;
    border-radius: 1rem;
    height: 10rem;
    cursor: pointer;
    margin-bottom: 1.5rem;

    &:hover {
        background-color: darken($n150, $colorHoverDarkenPercentage);
    }

    &:active {
        background-color: darken($n150, $colorActiveDarkenPercentage);
    }

    .SatzungenCard__icon {
        position: absolute;
        right: 1rem;
    }

    &.SatzungenCard--draft {
        background-color: $o100;
    }
}

@include media-breakpoint-down(xl) {
    .SatzungenCard {
        height: 8rem;
    }
}
